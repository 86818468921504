/**
 * Copyright ©2023 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Attribute source options component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";
import CarrierStore from "./CarrierStore";
import RoleStore from "./RoleStore";
import OrderStatusStore from "./OrderStatusStore";
import PaymentMethodStore from "./PaymentMethodStore";
import ShippingMethodStore from "./ShippingMethodStore";
import SupplierStore from "./SupplierStore";
import PrinterTypeStore from "./PrinterTypeStore";
import WebshopStore from "./WebshopStore";

class attributeSourceOptions extends EventEmitter {
    constructor() {
        super();
        this.data = {};
        this.sortedData = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        CarrierStore.addChangeListener("change", this.onStoreDataUpdate);
        RoleStore.addChangeListener("change", this.onStoreDataUpdate);
        OrderStatusStore.addChangeListener("change", this.onStoreDataUpdate);
        PaymentMethodStore.addChangeListener("change", this.onStoreDataUpdate);
        ShippingMethodStore.addChangeListener("change", this.onStoreDataUpdate);
        SupplierStore.addChangeListener("change", this.onStoreDataUpdate);
        PrinterTypeStore.addChangeListener("change", this.onStoreDataUpdate);
        WebshopStore.addChangeListener("change", this.onStoreDataUpdate);
    }

    componentWillUnmount() {
        CarrierStore.removeChangeListener("change", this.onStoreDataUpdate);
        RoleStore.removeChangeListener("change", this.onStoreDataUpdate);
        OrderStatusStore.removeChangeListener("change", this.onStoreDataUpdate);
        PaymentMethodStore.removeChangeListener("change", this.onStoreDataUpdate);
        ShippingMethodStore.removeChangeListener("change", this.onStoreDataUpdate);
        SupplierStore.removeChangeListener("change", this.onStoreDataUpdate);
        PrinterTypeStore.removeChangeListener("change", this.onStoreDataUpdate);
        WebshopStore.removeChangeListener("change", this.onStoreDataUpdate);
    }

    onStoreDataUpdate() {
        this.sortedData = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return all store
     * @returns {{}|*}
     */
    getAll() {
        return {
            carrier: CarrierStore.getAllById(),
            role: RoleStore.getAll(),
            order_status: OrderStatusStore.getAll(),
            payment_method: PaymentMethodStore.getAll(),
            shipping_method: ShippingMethodStore.getAll(),
            supplier: SupplierStore.getAll(),
            printer_type: PrinterTypeStore.getAll(),
            webshop: WebshopStore.getAll(),
        };
    }

    /**
     * Return all sorted options
     *
     * @returns {{}|*}
     */
    getAllSortedSource() {
        return {
            carrier: this.getSortedOptionSource("carrier_id"),
            role: this.getSortedOptionSource("role_id"),
            order_status: this.getSortedOptionSource("status"),
            payment_method: this.getSortedOptionSource("payment_method"),
            shipping_method: this.getSortedOptionSource("shipping_method"),
            supplier: this.getSortedOptionSource("supplier_id"),
            webshop: this.getSortedOptionSource("webshop_id"),
        };
    }

    /**
     * Get by name
     *
     * @param name
     * @return {*|{}}
     */
    getByName(name) {
        let all = this.getAll();
        return all.hasOwnProperty(name) ? all[name] : {};
    }

    /**
     * Get option source
     *
     * @param field
     * @return {boolean|{store_id: string, store_name: string, store: (*|{})}}
     */
    getOptionSource(field) {
        switch (field) {
            case "role_id":
                return {
                    store: this.getByName("role"),
                    store_id: "role_id",
                    store_name: "role_name"
                };
            case "status":
                return {
                    store: this.getByName("order_status"),
                    store_id: "status_id",
                    store_name: "label"
                };
            case "carrier_id":
                return {
                    store: this.getByName("carrier"),
                    store_id: "carrier_id",
                    store_name: "name"
                };
            case "payment_method":
                return {
                    store: this.getByName("payment_method"),
                    store_id: "code",
                    store_name: "label"
                };
            case "shipping_method":
                return {
                    store: this.getByName("shipping_method"),
                    store_id: "code",
                    store_name: "label"
                };
            case "supplier_id":
                return {
                    store: this.getByName("supplier"),
                    store_id: "supplier_id",
                    store_name: "name"
                };
            case "webshop_id":
                return {
                    store: this.getByName("webshop"),
                    store_id: "webshop_id",
                    store_name: "name"
                };
        }

        if (field === "webshop_status") {
            let webshops = this.getByName("webshop");
            let statusList = [];
            let options = [];
            for (let key in webshops) {
                try {
                    let webshopConfig = webshops[key].config;
                    let statusConfig = JSON.parse(webshopConfig);
                    for (let key in statusConfig) {
                        statusList[key] = statusConfig[key];
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            for (let key in statusList) {
                options.push({
                    value: key,
                    label: statusList[key]
                });
            }

            return {
                store: options,
                store_id: "value",
                store_name: "label"
            };
        }

        return false;
    }

    /**
     * Get sorted option source
     *
     * @param field
     * @return {{store_id: string, store_name: string, store: *[]}|boolean}
     */
    getSortedOptionSource(field) {

        let optionSource = this.getOptionSource(field),
            options = [];

        if (!this.sortedData.hasOwnProperty(field)) {
            if (!optionSource) {
                this.sortedData[field] = false;
                return false;
            }

            for (let key in optionSource.store) {
                options.push({
                    value: optionSource.store[key][optionSource.store_id],
                    label: optionSource.store[key][optionSource.store_name]
                });
            }

            options.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

            this.sortedData[field] = {
                store: options,
                store_id: "value",
                store_name: "label"
            };
        }

        return this.sortedData[field];
    }
}

const AttributeSourceOptions = new attributeSourceOptions;

export default AttributeSourceOptions;
