/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Search bar component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import {Card, IconButton} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import SortIcon from "@material-ui/icons/Sort";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import AttributeSourceOptions from "../../../../../stores/AttributeSourceOptions";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class SearchBar extends React.Component {
    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            search_filter: [{
                type: this.props.pageData.layout === "orders" ? "increment_id" : "",
                value: ""
            }],
            order_by: "",
            order_sort: "asc",
            search_type: "",
        };
        this.getSearchSelectElements = this.getSearchSelectElements.bind(this);
        this.getSearchValueElements = this.getSearchValueElements.bind(this);
        this.onChangeFieldSelect = this.onChangeFieldSelect.bind(this);
        this.onChangeValueSelect = this.onChangeValueSelect.bind(this);
        this.hasOptionSource = this.hasOptionSource.bind(this);
        this.getOptionSource = this.getOptionSource.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onOrderByChange = this.onOrderByChange.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.setState({search_text: this.props.search});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pageData.menu_id !== this.props.pageData.menu_id) {
            this.setState({
                search_filter: [{
                    type: this.props.pageData.layout === "orders" ? "increment_id" : "",
                    value: ""
                }],
                order_by: "",
                order_sort: "asc",
                search_type: "",
            });
        }
    }

    /**
     * Return elements for the search filter
     * @returns {[]}
     */
    getSearchSelectElements() {
        let header = this.props.header,
            result = [];

        header.forEach(function (item, index) {
            if (item.key !== "row_controls" && item.visible) {
                result.push(
                    <MenuItem key={index} value={item.key}>{item.name}</MenuItem>
                );
            }
        });

        return result;
    }

    /**
     * Return elements for the search filter
     * @param index
     * @returns {[]}
     */
    getSearchValueElements(index) {
        if (!this.state.search_filter.hasOwnProperty(index)) {
            return [];
        }

        let optionSource = this.getOptionSource(this.state.search_filter[index].type),
            result = [];

        if (optionSource) {
            result.push(
                <MenuItem key={-1} value={""}> &nbsp; </MenuItem>
            );

            for (let i in optionSource.store) {
                let item = optionSource.store[i];
                result.push(
                    <MenuItem key={item[optionSource.store_id]} value={item[optionSource.store_id]}>
                        {item[optionSource.store_name]}
                    </MenuItem>
                );
            }
        }

        return result;
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChangeFieldSelect(e) {
        let item = this.state.search_filter,
            index = e.target.name.split("_")[1],
            target = e.target.name.split("_")[0];

        item[index][target] = e.target.value;
        item[index].value = "";

        this.onChange(e);
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChangeValueSelect(e) {
        let item = this.state.search_filter,
            index = e.target.name.split("_")[2],
            target = e.target.name.split("_")[0];

        item[index][target] = e.target.value;

        this.forceUpdate();
    }

    /**
     * Save changes to component state
     * @param field
     */
    hasOptionSource(field) {
        return this.getOptionSource(field) !== false;
    }

    /**
     * @param {{string}} field
     * @return {{store_id: string, store_name: string, store}|boolean}
     */
    getOptionSource(field) {
        if (field === "") {
            return false;
        }

        if (this.props.pageData.layout === "order_statuses" && field === "webshop_status") {
            return false;
        }

        return AttributeSourceOptions.getSortedOptionSource(field);
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.search_filter,
            index = e.target.name.split("_")[1],
            target = e.target.name.split("_")[0];

        item[index][target] = e.target.value;
        this.forceUpdate();
    }

    onOrderByChange(e) {
        let item = this.state,
            target = e.target.name;

        item[target] = e.target.value;
        this.forceUpdate();
    }

    onOrderSortChange() {
        let item = this.state;
        item["order_sort"] = this.state.order_sort === "asc" ? "desc" : "asc";
        this.forceUpdate();
    }

    /**
     * Submit search
     * @param e
     */
    onSubmit(e) {
        e.preventDefault();
        this.props.onSearch(this.state.search_filter, {type: this.state.order_by, value: this.state.order_sort});
    }

    addFilterRow() {
        let filter = this.state.search_filter;
        filter.push({
            type: "",
            value: ""
        });
        this.forceUpdate();
    }

    removeFilterRow(index) {
        let filter = this.state.search_filter;
        delete filter[index];
        this.forceUpdate();
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            // classes,
            open,
            toggleFilter
        } = this.props;

        return (
            <SwipeableDrawer
                anchor="right"
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={open}
                onClose={toggleFilter}
                onOpen={toggleFilter}
            >
                <Paper component="form" elevation={0} style={{width: "320px", padding: "10px", backgroundColor: "#00000012", height: "100%"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}>
                        {this.state.search_filter.map((item, index) => (
                            <Card key={index} style={{width: "100%", padding: ".4rem", display: "flex", alignItems: "center", flexDirection: "column", marginBottom: ".2rem"}}>
                                <Select
                                    name={"type_" + index}
                                    style={{minWidth: "150px", width: "100%", textAlign: "left"}}
                                    className="search-select"
                                    value={item.type}
                                    color="secondary"
                                    placeholder="in"
                                    onChange={this.onChangeFieldSelect}
                                >
                                    {this.getSearchSelectElements()}
                                </Select>
                                <div>
                                    <IconButton
                                        style={{display: "none"}}
                                        type="button"
                                        aria-label="search"
                                        disabled={true}
                                    >
                                        <Typography variant={"h5"}
                                                    style={{display: "inline", width: "24px", lineHeight: "24px"}}>
                                            {"<"}
                                        </Typography>
                                    </IconButton>
                                    <IconButton
                                        type="button"
                                        aria-label="search"
                                    >
                                        <Typography variant={"h5"}
                                                    style={{display: "inline", width: "24px", lineHeight: "24px"}}>
                                            {"="}
                                        </Typography>
                                    </IconButton>
                                    <IconButton
                                        style={{display: "none"}}
                                        type="button"
                                        aria-label="search"
                                        disabled={true}
                                    >
                                        <Typography variant={"h5"}
                                                    style={{display: "inline", width: "24px", lineHeight: "24px"}}>
                                            {">"}
                                        </Typography>
                                    </IconButton>
                                </div>
                                <Input
                                    type={"text"}
                                    style={{
                                        width: "100%",
                                        display: this.hasOptionSource(item.type) ? "none" : "block"
                                    }}
                                    placeholder={""}
                                    value={item.value}
                                    name={"value_" + index}
                                    color="secondary"
                                    autoFocus={true}
                                    inputProps={{"aria-label": "search"}}
                                    onChange={this.onChange}
                                />
                                <Select
                                    name={"value_select_" + index}
                                    style={{
                                        minWidth: "150px",
                                        width: "100%",
                                        textAlign: "left",
                                        display: this.hasOptionSource(item.type) ? "block" : "none"
                                    }}
                                    MenuProps={{style: {maxHeight: 500}}}
                                    className="search-select"
                                    value={item.value}
                                    color="secondary"
                                    placeholder="in"
                                    onChange={this.onChangeValueSelect}
                                >
                                    {this.getSearchValueElements(index)}
                                </Select>
                                <div style={ { display: "flex", alignItems: "center"  }}
                                     onClick={() => {{this.removeFilterRow(index);}}}
                                > Szűrő Törlése <IconButton
                                    type="button"
                                    // className={classes.searchIconButton}
                                    aria-label="search"
                                >
                                    <RemoveIcon/>
                                </IconButton></div>
                            </Card>
                        ))}
                        <div style={ { display: "flex", alignItems: "center"  }}
                             onClick={() => {{this.addFilterRow();}}}
                        > Új Szűrő <IconButton
                            type="button"
                            // className={classes.searchIconButton}
                            aria-label="search"
                        >
                            <AddIcon/>
                        </IconButton></div>
                        <Card style={{width: "100%", padding: ".4rem", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <Typography style={{display: "inline"}}>
                            {"Sorba rendezés: "}
                        </Typography>
                            <div style={{display: "flex"}}>
                                <Select
                                    name={"order_by"}
                                    style={{minWidth: "150px", textAlign: "left"}}
                                    className="search-select"
                                    value={this.state.order_by}
                                    color="secondary"
                                    placeholder="in"
                                    onChange={this.onOrderByChange}
                                >
                                    {this.getSearchSelectElements()}
                                </Select>
                                <IconButton
                                    type="button"
                                    name={"order_sort"}
                                    // className={classes.searchIconButton}
                                    aria-label="search"
                                    onClick={(e) => {
                                        {
                                            this.onOrderSortChange(e);
                                        }
                                    }}
                                >
                                    <SortIcon
                                        style={{transform: this.state.order_sort === "asc" ? "rotate(180deg)" : "rotate(0deg)"}}/>
                                </IconButton></div>
                        </Card>
                        <IconButton
                            type="submit"
                            // className={classes.searchIconButton}
                            aria-label="search"
                            onClick={(e) => {
                                {
                                    this.onSubmit(e);
                                }
                            }}
                        >
                            <SearchIcon/>
                        </IconButton>
                    </div>
                </Paper>
            </SwipeableDrawer>
        );
    }
}

SearchBar.propTypes = {
    onSearch: PropTypes.func,
    toggleFilter: PropTypes.func,
    search: PropTypes.string,
    sort: PropTypes.any,
    pageData: PropTypes.any,
    open: PropTypes.any,
    header: PropTypes.any,
    classes: PropTypes.any
};

SearchBar.defaultProps = {
    search: "",
    open: false,
    sort: {},
    header: {},
    classes: {}
};

export default SearchBar;