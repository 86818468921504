/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Packaging component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../../services/Loader";
import FullscreenLoaderService from "../../../../services/FullscreenLoaderService";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Dialog from "../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";

import SpeedDial from "../../../common/speedDial";
import Header from "../common/header";
import ItemInfo from "../common/itemInfo";
import ProgressBar from "../common/progressBar";
import CodeInputDialog from "../common/codeInputDialog";

import NotificationService from "../../../../services/Notification";
import SettingsDataStore from "../../../../stores/SettingsDataStore";
import Suppliers from "../common/suppliers";
import OrderSaveDialog from "../common/orderSaveDialog";
import ItemNotNeedDialog from "../common/itemNotNeedDialog";
import OrderListDialog from "../common/orderListDialog";
import OrderInfoDialog from "../common/orderInfoDialog";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Package from "./package";
import PackageSizeDialog from "../common/packageSizeDialog";
import PleaseScan from "../common/pleaseScan";
import RepeatIcon from "@material-ui/icons/Repeat";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SaveIcon from "@material-ui/icons/Save";
import BarcodeReader from "../common/barcodeReader";
import FocusLock from "react-focus-lock";
import OrderNotEditableDialog from "../common/orderNotEditableDialog";
import {checkDownloadables} from "../../utils/downloadFile";

class Packaging extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.loading = false;
        this.keys = "";
        this.key_time = 0;
        this.rootRef = React.createRef();

        this.state = {
            scannerActive: true,
            order_info_dialog: false,
            order_list_dialog: false,
            is_too_mouch: false,
            total_count: 0,
            founded_count: 0,
            packages: [],
            actual_package: 90000,
            enter_code_dialogue: false,
            orders: [],
            data: false,
            actual_founded: false,
            alert_open: false,
            can_save: false,
            save_dialog: false,
            settings: {
                header: {
                    show_code_input: false
                }
            },
            bottom_nav_value: "",
            new_package_dialog: false,
            new_package_anchorEl: null,
            packageDimensionsDialog: false,
            package_info: {},
            editable: true,
            notEditableDialog: false,
            message: "",
            lastScanned: "",
            status: ""
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleClickOrderCode = this.handleClickOrderCode.bind(this);
        this.ordersSuccess = this.ordersSuccess.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.orderSuccess = this.orderSuccess.bind(this);
        this.getFounded = this.getFounded.bind(this);
        this.getDifference = this.getDifference.bind(this);
        this.searchItem = this.searchItem.bind(this);
        this.foundedChanged = this.foundedChanged.bind(this);
        this.increasePackageItem = this.increasePackageItem.bind(this);
        this.decreasePackageItem = this.decreasePackageItem.bind(this);
        this.getRowClassname = this.getRowClassname.bind(this);
        this.newOrderAlertClose = this.newOrderAlertClose.bind(this);
        this.newOrderAlertOk = this.newOrderAlertOk.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.checkReadyToSave = this.checkReadyToSave.bind(this);
        this.toggleShowEnterCode = this.toggleShowEnterCode.bind(this);
        this.closeCodeDialogue = this.closeCodeDialogue.bind(this);
        this.preventClose = this.preventClose.bind(this);
        this.saveOrderOk = this.saveOrderOk.bind(this);
        this.saveOrderSuccess = this.saveOrderSuccess.bind(this);
        this.notNeedOk = this.notNeedOk.bind(this);
        this.orderListCancel = this.orderListCancel.bind(this);
        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
        this.reset = this.reset.bind(this);
        this.newPackageDialogOpen = this.newPackageDialogOpen.bind(this);
        this.newPackageDialogClose = this.newPackageDialogClose.bind(this);
        this.addPackage = this.addPackage.bind(this);
        this.deselectPackage = this.deselectPackage.bind(this);
        this.selectPackage = this.selectPackage.bind(this);
        this.addActualFoundedToPackage = this.addActualFoundedToPackage.bind(this);
        this.togglePackageDimensionsDialog = this.togglePackageDimensionsDialog.bind(this);
        this.openPackageDimensionsDialog = this.openPackageDimensionsDialog.bind(this);
        this.onPackageSizeChange = this.onPackageSizeChange.bind(this);
        this.savePackageDimensionsDialog = this.savePackageDimensionsDialog.bind(this);
        this.canSavePackageSize = this.canSavePackageSize.bind(this);
        this.checkAllPackagesHasSize = this.checkAllPackagesHasSize.bind(this);
        this.cancelSaveOrder = this.cancelSaveOrder.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.searchBarcode = this.searchBarcode.bind(this);
        this.notEditableDialogOk = this.notEditableDialogOk.bind(this);
        this.setScannerActive = this.setScannerActive.bind(this);
        this.removePackage = this.removePackage.bind(this);
        this.saveOrderError = this.saveOrderError.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        let settings = SettingsDataStore.getData(this.props.pageData.layout);

        if (settings !== undefined) {
            this.setState({settings: settings});
        }
    }

    scrollToTop() {
        if (this.state.settings.scroll_to_top_on_scan) {
            this.rootRef.current.scrollTo(0, 0);
        }
    }

    addPackage(is_accessory) {
        let packages = this.state.packages;

        if (is_accessory === undefined) {
            is_accessory = false;
        }

        const lastPackageId = packages.length !== 0 ? packages[packages.length - 1].package_id : 0;

        packages.push({
            package_id: lastPackageId + 1,
            accessory: is_accessory,
            qty: 0, //TODO: Sanyi ezt most nem varja, de el lehetne menteni h ne kelljen szamolni
            weight: 0,
            dimension: {
                height: "",
                width: "",
                length: ""
            },
            items: []
        });

        this.setState({
            actual_package: packages.length - 1,
            new_package_dialog: false,
            bottom_nav_value: "",
            new_package_anchorEl: null
        });
    }

    /**
     * Removes package from packages array by ID
     * @param {number} package_id
     * @returns {void}
     */
    removePackage(package_id) {

        const currentPackage = this.state.packages.filter(pack => pack.package_id === package_id)[0];
        currentPackage.items.forEach(item => {
            this.state.data.suppliers.forEach(supplier => {
                supplier.items.forEach(supplierItem => {
                    if (supplierItem.item_id === item.item_id) {
                        supplierItem.founded -= item.qty;
                    }
                });
            });
        });

        const packages = this.state.packages.filter(pack => pack.package_id !== package_id);
        this.setState({
            packages,
            actual_package: this.state.actual_package - 1
        });
    }


    deselectPackage() {
        this.setState({actual_package: 90000});
    }

    selectPackage(id) {
        if (this.state.actual_package !== 90000) {
            return;
        }
        this.setState({actual_package: id});
    }

    reset() {
        this.setState({
            order_info_dialog: false,
            order_list_dialog: false,
            is_too_mouch: false,
            packages: [],
            actual_package: 90000,
            total_count: 0,
            founded_count: 0,
            enter_code_dialogue: false,
            speedDial_open: false,
            orders: [],
            data: false,
            actual_founded: false,
            alert_open: false,
            can_save: false,
            save_dialog: false,
            bottom_nav_value: "",
            new_package_dialog: false,
            new_package_anchorEl: null,
            packageDimensionsDialog: false,
            package_info: {},
            status: ""
        });
    }

    /**
     * Show/Hide enter code dialogue
     */
    toggleShowEnterCode() {
        this.setState({enter_code_dialogue: !this.state.enter_code_dialogue});
    }

    closeCodeDialogue() {
        if (!this.state.enter_code_dialogue) {
            return false;
        }
        this.setState({enter_code_dialogue: false});
    }

    /**
     * Check order is ready to save
     */
    checkReadyToSave() {
        let total_count = 0,
            founded_count = 0,
            _can_save = true;

        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item) {
                if (item.founded !== Number(item.qty)) {
                    _can_save = false;
                }
                total_count += Number(item.qty);
                if (item.hasOwnProperty("founded")) {
                    founded_count += Number(item.founded);
                }
            });
        });

        if (_can_save) {
            _can_save = this.checkAllPackagesHasSize();
        }

        this.setState({
            can_save: _can_save,
            save_dialog: _can_save,
            total_count: total_count,
            founded_count: founded_count
        });
    }

    /**
     * Handle new order request form the user
     * @returns {boolean}
     */
    newOrder() {
        if (this.state.data !== false) {
            this.setState({alert_open: true, speedDial_open: false});
            return false;
        }

        this.reset();
    }


    /**
     * Return the row classname depend on founded calculation
     * @param item
     * @returns {string}
     */
    getRowClassname(item) {
        if (!item.hasOwnProperty("founded") || item.founded === 0) {
            return " normal";
        }

        if (Number(item.qty) > item.founded) {
            return " yellow";
        }

        if (item.founded > Number(item.qty)) {
            return " red blink";
        }

        if (item.founded === Number(item.qty)) {
            return " green";
        }
    }

    /**
     * Increase item count in package
     * @param _item
     */
    increasePackageItem(_item) {
        let founded = false,
            self = this,
            packages = this.state.packages;

        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item, item_index) {
                if (item.item_id === _item.item_id) {
                    founded = true;
                    if (Number(item.qty) === Number(item.founded)) {
                        self.setState({is_too_mouch: true});
                        return false;
                    }
                    _item.qty++; // increase item in package
                    _item.founded++; // increase item in package //TODO remove if Sanyi fixed the save method
                    item.founded++; // increase item founded in supplier
                    packages[self.state.actual_package].qty++;
                    packages[self.state.actual_package].weight += Number(item.weight);
                    self.setState({actual_founded: {item: item, index: item_index}});
                    return true;
                }
            });
            if (founded) {
                return true;
            }
        });
        this.checkReadyToSave();
    }

    /**
     * Decrease item count in package
     * the 0 is the limit of the minimum
     * @param _item
     */
    decreasePackageItem(_item) {
        if (Number(_item.qty) === 0) {
            return;
        }

        let founded = false,
            self = this,
            packages = this.state.packages;

        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item, item_index) {
                if (item.item_id === _item.item_id) {
                    founded = true;
                    _item.qty--; // decrease item in package
                    _item.founded--; // decrease item in package //TODO remove if Sanyi fixed the save method
                    item.founded--; // decrease item founded in supplier
                    packages[self.state.actual_package].qty--;
                    packages[self.state.actual_package].weight -= Number(item.weight);
                    self.setState({actual_founded: {item: item, index: item_index}});
                    return true;
                }
            });
            if (founded) {
                return true;
            }
        });
        this.checkReadyToSave();
    }

    foundedChanged(founded_item, value) {
        founded_item.item.founded = value;
        this.setState({actual_founded: founded_item});
        this.checkReadyToSave();
    }

    /**
     * Get founded count or create if not exists
     * @param tableIndex
     * @param index
     * @returns {number|number|*}
     */
    getFounded(tableIndex, index) {
        if (this.state.data.suppliers) {
            if (this.state.data.suppliers[tableIndex].items[index].hasOwnProperty("founded")) {
                return this.state.data.suppliers[tableIndex].items[index].founded;
            }
        }
        return 0;
    }

    /**
     * Return the founded and qty difference
     * @param tableIndex
     * @param index
     * @returns {number}
     */
    getDifference(tableIndex, index) {
        let founded = 0;
        if (this.state.data.suppliers) {
            if (this.state.data.suppliers[tableIndex].items[index].hasOwnProperty("founded")) {
                founded = this.state.data.suppliers[tableIndex].items[index].founded;
            }
            return Number(this.state.data.suppliers[tableIndex].items[index].qty) - Number(founded);
        }

        return 0;
    }


    handleClickOrderCode(order_code) {
        if (order_code === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({lastScanned: order_code});
        this.closeCodeDialogue();
        this.fetchData("packaging/" + this.props.pageData.menu_id + "/" + order_code, this.ordersSuccess);
    }

    loadOrder(id) {
        if (id === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({order_list_dialog: false});
        // this.closeCodeDialogue();
        this.fetchData("packaging/" + this.props.pageData.menu_id + "/" + id, this.ordersSuccess);
    }

    searchItem(item_code) {
        let self = this,
            isTooMouch = false,
            isFounded = false;

        if (item_code === "") {
            NotificationService.showNotification("error", "The item code is empty");
            return false;
        }

        if (!this.state.data.hasOwnProperty("suppliers")) {
            NotificationService.showNotification("error", "Please enter the order code first");
            return false;
        }

        this.setState({lastScanned: item_code});
        this.closeCodeDialogue();

        if (!this.state.data.ean.hasOwnProperty(item_code)) {
            this.setState({actual_founded: false});
            NotificationService.showNotification("error", "The item not found in this order");
            return false;
        }

        let ean_items = this.state.data.ean[item_code];

        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item, item_index) {
                ean_items.forEach(function (ean_item) {
                    if (ean_item === item.item_id) {
                        if (!item.hasOwnProperty("founded")) {
                            item.founded = 0;
                        }
                        if (Number(item.qty) === Number(item.founded)) {
                            isTooMouch = true;
                        } else {
                            item.founded++;
                            isFounded = true;
                            self.setState({actual_founded: {item: item, index: item_index}});
                            self.addActualFoundedToPackage(self.cloneObject(item));
                        }
                    }
                });
            });
        });

        if (isTooMouch && !isFounded) {
            this.setState({is_too_mouch: true});
            return false;
        }
        self.checkReadyToSave();
    }

    cloneObject(src) {
        return Object.assign({}, src);
    }

    addActualFoundedToPackage(_item) {
        let packages = this.state.packages,
            in_package = false,
            self = this;

        packages[this.state.actual_package].items.forEach(function (item, index) {
            if (item.item_id === _item.item_id) {
                packages[self.state.actual_package].items[index].qty++;
                packages[self.state.actual_package].items[index].founded++; //TODO remove if Sanyi fixed the save method
                in_package = true;
                return;
            }
        });

        if (!in_package) {
            delete _item.founded;
            _item.qty = 1;
            _item.founded = 1; //TODO remove if Sanyi fixed the save method
            packages[this.state.actual_package].items.push(_item);
            // console.log("not in the package ", packages, _item);
        }
        packages[this.state.actual_package].qty++;
        packages[this.state.actual_package].weight += Number(_item.weight);
        this.forceUpdate();
    }

    ordersSuccess(result) {
        this.loading = false;

        if (result.hasOwnProperty("_embedded")) {
            if (result._embedded.length === 1) {
                if (result._embedded[0].hasOwnProperty("suppliers")) {
                    if (!result._embedded[0].editable) {
                        this.setState({
                            data: false,
                            orders: [],
                            just_item_info: false,
                            notEditableDialog: !result._embedded[0].editable,
                            editable: result._embedded[0].editable,
                            message: result._embedded[0].message,
                            status: result._embedded[0].status
                        });
                        return;
                    }
                    this.setState({data: result._embedded[0], orders: []});
                    this.addPackage();
                    this.checkReadyToSave();
                    return;
                }

                if (result._embedded[0].hasOwnProperty("increment_id")) {
                    this.loadOrder(result._embedded[0].increment_id);
                    this.setState({orders: []});
                    return;
                }
            }
            this.setState({orders: result._embedded, order_list_dialog: true});
        }
    }

    orderSuccess(result) {
        this.loading = false;

        if (result.hasOwnProperty("status")) {
            this.setState({data: result});
            this.addPackage();
            this.checkReadyToSave();
        }
    }

    fetchData(what, callback) {
        this.loading = true;
        LoaderService.getData(what, "", callback);
    }

    newOrderAlertClose() {
        this.setState({alert_open: false});
    }

    newOrderAlertOk() {
        this.reset();
    }

    preventClose(e) {
        e.preventDefault();
    }

    saveOrderOk(status) {
        FullscreenLoaderService.showLoader();
        let _data = this.cloneObject(this.state.data);
        _data.packages = this.state.packages;
        _data.status = status;
        delete _data.items;
        LoaderService.postData("packaging/" + this.props.pageData.menu_id + "/" + _data.increment_id, _data, (res) => this.saveOrderSuccess(res), this.saveOrderError);
    }

    saveOrderSuccess(result) {
        FullscreenLoaderService.hideLoader();
        if (result.hasOwnProperty("_embedded")) {
            result._embedded.forEach( res => {
                checkDownloadables(res);
            });
        }

        if (result.hasOwnProperty("_embedded")) {
            this.reset();
        }
    }

    saveOrderError() {
        FullscreenLoaderService.hideLoader();
        NotificationService.showNotification("error", "Could not save item");
    }

    notNeedOk() {
        this.setState({is_too_mouch: false});
    }

    notEditableDialogOk() {
        this.setState({notEditableDialog: false});
    }

    orderListCancel() {
        this.setState({order_list_dialog: false});
    }

    closeOrderInfo() {
        this.setState({order_info_dialog: false});
    }

    openOrderInfo() {
        this.setState({order_info_dialog: true});
    }

    newPackageDialogOpen(event) {
        this.setState({new_package_dialog: true, new_package_anchorEl: event.currentTarget});
        // setAnchorEl(event.currentTarget);
    }

    newPackageDialogClose() {
        this.setState({new_package_dialog: false, bottom_nav_value: "", new_package_anchorEl: null});
    }

    togglePackageDimensionsDialog(pack) {
        if (!this.state.packageDimensionsDialog) {
            pack.new_dimension = this.cloneObject(pack.dimension);
            this.setState({package_info: pack, packageDimensionsDialog: true});
        } else {
            delete pack.new_dimension;
            this.setState({packageDimensionsDialog: false, package_info: {}});
        }
    }

    openPackageDimensionsDialog(pack) {
        pack.new_dimension = this.cloneObject(pack.dimension);
        this.setState({package_info: pack, packageDimensionsDialog: true});
    }

    /**
     * Save package size
     * @param pack
     */
    savePackageDimensionsDialog(pack) {
        if (pack.new_dimension.height !== "" && pack.new_dimension.width !== "" && pack.new_dimension.length !== "") {
            pack.dimension = pack.new_dimension;
            delete pack.new_dimension;
            this.setState({packageDimensionsDialog: false, package_info: {}});
            this.checkReadyToSave();
        } else {
            //TODO show error
        }
    }

    /**
     * Handle package size input value changes
     * and accept empty and number values (included decimal)
     * @param e
     */
    onPackageSizeChange(e) {
        let package_info = this.state.package_info,
            val = e.target.value,
            pattern = /^-?\d*\.?\d*$/;

        if (pattern.test(val)) {
            package_info.new_dimension[e.target.name] = e.target.value;
            this.forceUpdate();
        }
    }

    /**
     * Check the new package size is ready to save
     * @returns {boolean}
     */
    canSavePackageSize() {
        if (!this.state.package_info.hasOwnProperty("new_dimension")) {
            return false;
        }
        return (this.state.package_info.new_dimension.height === "" || this.state.package_info.new_dimension.width === "" || this.state.package_info.new_dimension.length === "");
    }

    /**
     * Check all packages has size or not
     * @returns {boolean}
     */
    checkAllPackagesHasSize() {
        let ready = true,
            self = this;

        this.state.packages.forEach(function (pack) {

            const missingDimensions = (pack.dimension.height === "" || pack.dimension.width === "" || pack.dimension.length === "");
            const hasProductQuantity = pack.qty !== 0;
            if (missingDimensions && hasProductQuantity && ready) {
                setTimeout(function () {
                    self.openPackageDimensionsDialog(pack);
                }, 150);
                ready = false;
            }
        });

        return ready;
    }

    cancelSaveOrder() {
        this.setState({save_dialog: false});
    }


    searchBarcode(keys) {
        if (this.state.data !== false) {
            if (keys !== undefined) {
                this.searchItem(keys);
            }
        } else {
            if (keys !== undefined) {
                this.handleClickOrderCode(keys);
            }
        }
    }

    setScannerActive(val) {
        this.setState({scannerActive: val});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            classes,
            pageData
        } = this.props;

        return (
            <div className={classes.tableRoot} ref={this.rootRef}>
                <FocusLock>
                    <BarcodeReader
                        onEnter={this.searchBarcode}
                        preventDefault={false}
                        disabled={!this.state.scannerActive || this.state.notEditableDialog || this.state.save_dialog || this.state.order_info_dialog || this.state.alert_open || this.state.order_list_dialog || this.state.is_too_mouch || this.state.packageDimensionsDialog}
                    />
                    <Header
                        classes={classes}
                        handleClickOrderCode={this.handleClickOrderCode}
                        searchItem={this.searchItem}
                        status={this.state.data.status}
                        webshopStatus={this.state.data.webshop_status_label}
                        vendors={this.state.data.vendors}
                        created={this.state.data.created_at_date}
                        settings={this.state.settings.header}
                        showItemInfo={this.state.settings.show_product_on_order_list}
                        just_item_info={this.state.just_item_info}
                    >
                        <Grid item xs={12}>
                            {this.state.data.hasOwnProperty("suppliers") && (
                                <ProgressBar
                                    key={"progress_bar"}
                                    lastScanned={this.state.lastScanned}
                                    total={this.state.total_count}
                                    actual={this.state.founded_count}
                                    percentOne={this.state.founded_count === 0 ? 0 : Number(this.state.founded_count) / Number(this.state.total_count) * 100}
                                />
                            )}
                            {this.state.actual_founded && (
                                <Slide direction="down" in={this.state.actual_founded !== false} mountOnEnter
                                       unmountOnExit>
                                    <ItemInfo founded_item={this.state.actual_founded}/>
                                </Slide>
                            )}
                        </Grid>
                    </Header>

                    {this.state.packages.length > 0 && (
                        <Package
                            togglePackageDimensionsDialog={this.togglePackageDimensionsDialog}
                            packages={this.state.packages}
                            selectPackage={this.selectPackage}
                            selectedPackage={this.state.actual_package}
                            getRowClassname={this.getRowClassname}
                            increasePackageItem={this.increasePackageItem}
                            decreasePackageItem={this.decreasePackageItem}
                            removePackage={this.removePackage}
                        />
                    )}

                    {this.state.data.hasOwnProperty("suppliers") && (
                        <Suppliers group={this.state.settings.group_by_supplier} title={"Waiting for packaging"}
                                   suppliers={this.state.data.suppliers}  show_done={false}
                                   getRowClassname={this.getRowClassname} getFounded={this.getFounded}
                                   getDifference={this.getDifference}/>
                    )}

                    {!this.state.data.hasOwnProperty("suppliers") && (
                        <PleaseScan title={pageData.title}/>
                    )}

                    {(this.state.data || !this.state.settings.header.show_code_input) && (
                        <SpeedDial
                            actions={[
                                {
                                    icon: <SearchIcon/>,
                                    label: "Search",
                                    className: !this.state.data && !this.state.enter_code_dialogue ? "highlight" : "",
                                    tooltipTitle: "Search",
                                    onClick: () => this.toggleShowEnterCode(),
                                    condition: !this.state.settings.header.show_code_input
                                },
                                {
                                    icon: <InfoIcon/>,
                                    label: "Details",
                                    className: !this.state.data && !this.state.enter_code_dialogue ? "highlight" : "",
                                    tooltipTitle: "Details",
                                    onClick: () => this.openOrderInfo(),
                                    condition: this.state.data
                                },
                                {
                                    icon: <SaveIcon/>,
                                    label: "Save",
                                    className: this.state.can_save ? "highlight" : "",
                                    tooltipTitle: "Details",
                                    onClick: () => this.saveOrderOk(),
                                    condition: this.state.can_save
                                },
                                {
                                    icon: <AddCircleOutlineIcon/>,
                                    label: "New",
                                    className: this.state.can_save ? "highlight" : "",
                                    tooltipTitle: "New",
                                    onClick: () => this.newOrder(),
                                    condition: this.state.data
                                },

                            ]}
                            isHighlit={(!this.state.data && !this.state.enter_code_dialogue) || this.state.can_save}>
                        </SpeedDial>
                    )}
                    <Dialog
                        title="Are you sure to load new order?"
                        open={this.state.alert_open}
                        onClose={this.newOrderAlertClose}
                        onCancel={this.newOrderAlertClose}
                        onOk={this.newOrderAlertOk}
                        okText="Agree"
                        cancelText="Disagree"
                    >
                        <DialogContentText>
                            All not saved data for this order will be lost
                        </DialogContentText>
                    </Dialog>

                    <OrderListDialog
                        open={this.state.order_list_dialog}
                        onClose={this.orderListCancel}
                        onCancel={this.orderListCancel}
                        onSelect={this.loadOrder}
                        orders={this.state.orders}
                    />

                    <OrderInfoDialog
                        open={this.state.order_info_dialog}
                        onClose={this.closeOrderInfo}
                        onCancel={this.closeOrderInfo}
                        order={this.state.data}
                    />

                    <OrderSaveDialog
                        statuses={this.props.pageData.order_status_to}
                        open={this.state.save_dialog}
                        onClose={this.preventClose}
                        onOk={this.saveOrderOk}
                        onCancel={this.cancelSaveOrder}
                    />

                    <ItemNotNeedDialog
                        open={this.state.is_too_mouch}
                        onClose={this.preventClose}
                        onOk={this.notNeedOk}
                    />

                    <OrderNotEditableDialog
                        open={this.state.notEditableDialog}
                        onClose={this.preventClose}
                        onOk={this.notEditableDialogOk}
                        message={this.state.message}
                        status={this.state.status}
                    />

                    <CodeInputDialog
                        open={this.state.enter_code_dialogue}
                        onClose={this.toggleShowEnterCode}
                        onCancel={this.toggleShowEnterCode}
                        onChange={this.onChange}
                        hasOrder={this.state.data !== false}
                        searchOrder={this.handleClickOrderCode}
                        searchItem={this.searchItem}
                    />

                    {this.state.packages.length > 0 && (
                        <PackageSizeDialog
                            open={this.state.packageDimensionsDialog}
                            onClose={this.preventClose}
                            onOk={this.savePackageDimensionsDialog}
                            okDisabled={this.canSavePackageSize()}
                            onCancel={this.togglePackageDimensionsDialog}
                            onChange={this.onPackageSizeChange}
                            pack={this.state.package_info}
                        />
                    )}

                    <Menu
                        id="new-package-selector"
                        anchorEl={this.state.new_package_anchorEl}
                        open={this.state.new_package_dialog}
                        onClose={this.newPackageDialogClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem
                            onClick={() => {
                                {
                                    this.addPackage();
                                }
                            }}
                        >
                            <ListItemIcon>
                                <AddToPhotosIcon fontSize="small"/>
                            </ListItemIcon>
                            <Typography variant="inherit">New package</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                {
                                    this.addPackage(true);
                                }
                            }}
                        >
                            <ListItemIcon>
                                <PostAddIcon fontSize="small"/>
                            </ListItemIcon>
                            <Typography variant="inherit">New accessory package</Typography>
                        </MenuItem>
                    </Menu>
                    <Slide direction="up" in={this.state.data !== false} mountOnEnter unmountOnExit>
                        <BottomNavigation
                            className="bottom-navi"
                            value={this.state.bottom_nav_value}
                            onChange={this.handleBottomNavChange}
                        >
                            <BottomNavigationAction
                                className="button-hover-fix"
                                label="Add new package"
                                value="add_package"
                                icon={<AddToPhotosIcon/>}
                                style={{
                                    borderBottomLeftRadius: 34,
                                    borderTopLeftRadius: 34,
                                    color: "rgba(255, 255, 255, 0.7)"
                                }}
                                onClick={(event) => {
                                    this.newPackageDialogOpen(event);
                                }}
                            />
                            <BottomNavigationAction
                                className="button-hover-fix"
                                label="Select package"
                                value="select_package"
                                icon={<RepeatIcon/>}
                                style={{
                                    borderBottomRightRadius: 34,
                                    borderTopRightRadius: 34,
                                    color: "rgba(255, 255, 255, 0.7)"
                                }}
                                onClick={() => {
                                    this.deselectPackage();
                                }}
                            />
                        </BottomNavigation>
                    </Slide>
                </FocusLock>
            </div>
        );
    }
}

Packaging.propTypes = {
    url: PropTypes.any,
    pageData: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    ACL: PropTypes.any
};

Packaging.defaultProps = {
    url: false,
    classes: {}
};

export default Packaging;