/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Login component
 * ---------------
 * - show login dialog if the user is not logged in
 * - user login functions
 * - auto login functionality - TODO: separate this functionality in the future
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
// import ReCAPTCHA from "react-google-recaptcha";

/**
 * Import used stores
 */
import userDataStore from "../../stores/UserDataStore";
import URLDataStore from "../../stores/UrlDataStore";

/**
 * Import used services
 */
import AutologinService from "../../services/AutoLogin";
import LoaderService from "../../services/Loader";

/**
 * Import other used components
 */
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from "../common/dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import notificationService from "../../services/Notification";

import RoundedInput from "../common/input/rounded";
import RoundedTextField from "../common/textfield/rounded";
import SupplierService from "../../services/Supplier";
import ShippingMethodService from "../../services/ShippingMethod";
import OrderStatusService from "../../services/orderStatus";
import DeliveryPartnerService from "../../services/DeliveryPartner";
import PaymentMethodService from "../../services/PaymentMethod";
import UserRoleService from "../../services/UserRole";
import MenuService from "../../services/Menu";
import RoleService from "../../services/Role";
import {Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ViewportStore from "../../stores/ViewportStore";
import WebshopService from "../../services/Webshop";
import PrinterService from "../../services/Printer";

export default class Login extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            showPassword: false,
            open: false,
            zebra: ViewportStore.get(),
            needReCAPTCHA: false
        };


        /**
         * Bind functions to this
         */
        this.fetchLogin = this.fetchLogin.bind(this);
        this.loginSuccess = this.loginSuccess.bind(this);
        this.meSuccess = this.meSuccess.bind(this);
        this.stopServices = this.stopServices.bind(this);
        this.startServices = this.startServices.bind(this);
        this.checkToken = this.checkToken.bind(this);
        this.onReCAPTCHAErrored = this.onReCAPTCHAErrored.bind(this);
        this.recaptchaRef = React.createRef();

        /**
         * Start AutologinService
         */
        AutologinService.tryLogin(this.loginSuccess);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        /**
         * Get token
         */
        this.checkToken();
        userDataStore.addChangeListener("userChange", this.checkToken);
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
    }

    componentWillUnmount() {
        userDataStore.removeChangeListener("userChange", this.checkToken);
    }

    /**
     * Check the user is logged in
     */
    checkToken() {
        if (userDataStore.getData("token") === "") {
            this.setState({open: true});
            this.stopServices();
        }
    }

    startServices() {
        UserRoleService.start(60);
        SupplierService.start(60.1);
        OrderStatusService.start(60.2);
        ShippingMethodService.start(60.3);
        DeliveryPartnerService.start(60.4);
        PaymentMethodService.start(60.5);
        MenuService.start(60.6);
        RoleService.start(60.7);
        WebshopService.start(60.8);
        PrinterService.start(60.9);
    }

    stopServices() {
        UserRoleService.stop();
        SupplierService.stop();
        OrderStatusService.stop();
        ShippingMethodService.stop();
        DeliveryPartnerService.stop();
        PaymentMethodService.stop();
        MenuService.stop();
        RoleService.stop();
        WebshopService.stop();
        PrinterService.stop();
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Handle login success
     * @param result
     */
    loginSuccess(result) {
        if (result.hasOwnProperty("access_token")) {
            /**
             * Save user data to userDataStore
             */
            userDataStore.setData("scope", result.scope);
            userDataStore.setData("refresh_token", result.refresh_token);
            userDataStore.setData("token", result.access_token);

            /**
             * Save data for AutologinService
             */
            if (!AutologinService.isAutologin()) {
                // AutologinService.saveData({ email: this.state.email, password: this.state.password});
            }

            notificationService.showNotification("success", "Login success");
            LoaderService.getData("me", "", this.meSuccess);
        }
    }

    /**
     * Handle me success
     * @param result
     */
    meSuccess(result) {
        if (result.hasOwnProperty("user")) {
            userDataStore.setData("user", result.user);
        }
        if (result.hasOwnProperty("acl")) {
            userDataStore.setData("acl", result.acl);
        }

        this.setState({open: false, email: "", password: ""});
        this.startServices();
    }


    /**
     * Check Google ReCAPTCHA and fetch login data from server
     * @param e
     */
    fetchLogin(e) {
        e.preventDefault();

        // Check Google ReCAPTCHA
        /*
        const recaptchaValue = this.recaptchaRef.current.getValue();
        if (this.state.needReCAPTCHA && !recaptchaValue){
            notificationService.showNotification("error", "Invalid CAPTCHA");
            return;
        }
         */
        // Check Google ReCAPTCHA - END

        LoaderService.postData(URLDataStore.getData("defaultLogin"), {
            grant_type: "password",
            client_id: "client",
            username: this.state.email,
            password: this.state.password
        }, this.loginSuccess);
    }

    /**
     * Show/Hide password
     */
    handleClickShowPassword() {
        this.setState({"showPassword": !this.state.showPassword});
    }

    /**
     * Prevent to close the login modal
     * @param e
     */
    handleClose(e) {
        e.preventDefault();
    }

    onReCAPTCHAErrored() {
        this.setState({"needReCAPTCHA": false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const { themeColor } = this.props;

        if (!this.state.open) return null;

        if (this.state.zebra) {
            return (
                <div style={{
                    background: "#787878",
                    position: "fixed",
                    height: "100%",
                    width: "100%"
                }}>
                    <Paper style={{
                        zIndex: 1,
                        position: "fixed",
                        width: "90vw",
                        padding: "1rem",
                        margin: "5vw",
                    }}>
                        {loginContent(this.onChange, this.state.password, this.state.email, this.state.showPassword, this.handleClickShowPassword, this.recaptchaRef, this.state.zebra, this.onReCAPTCHAErrored, themeColor, this)}
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={this.fetchLogin}
                        >
                            LOG IN
                        </Button>
                    </Paper>
                </div>
            );
        }

        return (
            <div style={{marginTop: "-5rem"}}>
                <Dialog
                    title={this.state.zebra ? "" : "Vendor login"}
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    onOk={this.fetchLogin}
                    okText="Login"
                    maxWidth={this.state.zebra ? "lg" : "xs"}
                    fullWidth={this.state.zebra}
                >
                    <DialogContentText id="alert-dialog-slide-description">
                        {!this.state.zebra ? "You can login with your Username or Email" : ""}
                    </DialogContentText>
                    {loginContent(this.onChange, this.state.password, this.state.email, this.state.showPassword, this.handleClickShowPassword, this.recaptchaRef, this.zebra, this.onReCAPTCHAErrored, themeColor, this)}
                </Dialog>
            </div>
        );
    }
}


const loginContent = (onChange, password, email, showPassword, handleClickShowPassword, recaptchaRef, zebra, onReCAPTCHAErrored, themeColor, self) => (
    <React.Fragment>
    <div className="row" style={{
        height: "144px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly"
    }}>
        <div className="col-xs-12">
            <RoundedTextField
                label="Felhasználónév / Email"
                id="login-email"
                name="email"
                variant="outlined"
                color="secondary"
                className="full-width"
                value={email}
                onChange={onChange.bind(self)}
                // helperText="Required field"
                margin="dense"
            />
        </div>
        <div className="col-xs-12">
            <FormControl
                variant="outlined"
                color="secondary"
                className="full-width"
                margin="dense"
            >
                <InputLabel htmlFor="login-password">Jelszó / Password </InputLabel>
                <RoundedInput
                    id="login-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={onChange.bind(self)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                margin="dense"
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword.bind(self)}
                            >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                    labelWidth={135}
                />
            </FormControl>
        </div>
    </div>
    <div className="row">
    <div className="col-xs-12" style={{
        width: "fit-content",
        margin: "auto"
        }}>
    </div>
    </div>
    </React.Fragment>
);

Login.propTypes = {
    themeColor: PropTypes.string
};

Login.defaultProps = {
    themeColor: "light"
};