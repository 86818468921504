/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Header component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import getClassNames from "../utils/getClassNames";
import CancelIcon from "@material-ui/icons/Cancel";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import LoaderService from "../../../../../services/Loader";
import notificationService from "../../../../../services/Notification";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import DeleteIcon from "@material-ui/icons/Delete";
import StatusSelectorDialog from "../../../statusSelectorDialog";
import {downloadFile,checkDownloadables} from "../../../utils/downloadFile";
import ViewportStore from "../../../../../stores/ViewportStore";

class TheTableCell extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.getItem = this.getItem.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.printSuccess = this.printSuccess.bind(this);
        this.onSave = this.onSave.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
        this.state = {
            zebra: ViewportStore.get(),
            status_selector_open: false,
            statusSelectorCallback: null
        };


    }

    componentDidMount() {
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
 }

    /**
     * Print
     */
    onPrint(selected_status) {
        LoaderService.postData("packing_slip", {
            "order_id": [
                this.props.row.entity_id
            ],
            "order_status_to": +selected_status,
            "menu_id": this.props.pageData.menu_id
        }, this.printSuccess);
    }

    /**
     * Print success
     * @param res
     */
    printSuccess(res) {
        checkDownloadables(res);

        if (res.hasOwnProperty("success") && res.success === true) {
            notificationService.showNotification("success", "Print success");
            this.props.reload();
        }
    }

    /**
     * Save
     */
    onSave(selected_status) {
        LoaderService.patchData("sales_order/" + this.props.row.entity_id, {
            "status": +selected_status,
            "menu_id": this.props.pageData.menu_id
        }, this.saveSuccess);
    }

    /**
     * Save success
     * @param res
     */
    saveSuccess(res) {
        if (res["force_download"] && res["download"]) {
            const {content_base64, filename} = res.download;
            downloadFile(content_base64, filename);
        }

        if (res.hasOwnProperty("entity_id")) {
            notificationService.showNotification("success", "Save success");
            this.props.reload();
        }
    }

    /**
     * Return the row control elements
     */
    getRowControls() {
        let result = [];

        if (this.props.onEdit !== undefined) {
            result.push(
                <IconButton
                    key={"edit_icon_btn"}
                    onClick={() => {
                        {
                            this.props.onEdit(this.props.row, this.props.row_index);
                        }
                    }}
                >
                    <EditIcon/>
                </IconButton>
            );
        }

        if (this.props.pageData.layout === "orders") {
            if (this.props.general.hasOwnProperty("row_save") && this.props.general.row_save) {
                result.push(
                    <IconButton
                        key={"save_icon_btn"}
                        className={"RowSaveIconBtn"}
                        onClick={() => {
                            {
                                if (this.props.pageData.order_status_to.length <= 1) {
                                    this.onSave(this.props.pageData.order_status_to[0]);
                                } else {
                                    this.props.setDisableClick(true);
                                    this.setState({
                                        status_selector_open: true,
                                        statusSelectorCallback: (selected_status) => {
                                            this.props.setDisableClick(false);
                                            this.setState({status_selector_open: false}, () => {
                                                this.onSave(selected_status);
                                            });
                                        }
                                    });
                                }
                            }
                        }}
                    >
                        <SaveIcon style={{fontSize: "2rem"}}/>
                    </IconButton>
                );
            }
            if (this.props.general.hasOwnProperty("row_print") && this.props.general.row_print) {
                result.push(
                    <IconButton
                        key={"print_icon_btn"}
                        onClick={() => {
                            if (this.props.pageData.order_status_to.length <= 1) {
                                this.onPrint(this.props.pageData.order_status_to[0]);
                            } else {
                                this.props.setDisableClick(true);
                                this.setState({
                                    status_selector_open: true,
                                    statusSelectorCallback: (selected_status) => {
                                        this.props.setDisableClick(false);
                                        this.setState({status_selector_open: false}, () => {
                                            this.onPrint(selected_status);
                                        });
                                    }
                                });
                            }
                        }}>
                        <PrintIcon style={{fontSize: "2rem"}}/>
                    </IconButton>
                );
            }
        }

        if (this.props.onDelete !== undefined && this.props.acl.hasOwnProperty("delete") && this.props.acl.delete) {
            result.push(
                <IconButton
                    key={"delete_icon_btn"}
                    color={"primary"}
                    onClick={() => {
                        {
                            this.props.onDelete(this.props.row);
                        }
                    }}
                >
                    <DeleteIcon/>
                </IconButton>
            );
        }
        return result;
    }

    /**
     * Return the col value
     * @param key
     * @param item
     * @returns {string | *|string|*[]}
     */
    getItem(key, item) {
        const stores = this.props.stores;
        let supplier = "";

        switch (key) {
            case "row_controls":
                return this.getRowControls();
            case "carrier_id":
                return stores.carrier[item] !== undefined ? stores.carrier[item]["name"] : item;
            case "role_id":
                return stores.role[item] !== undefined ? stores.role[item]["role_name"] : item;
            case "status":
            case "order_status":
            case "complete_status":
                return stores.order_status[item] !== undefined ? stores.order_status[item]["label"] : item;
            case "payment_method":
                return stores.payment_method[item] !== undefined ? stores.payment_method[item]["label"] : item;
            case "shipping_method":
            case "carrier_type":
                return stores.shipping_method[item] !== undefined ? stores.shipping_method[item]["label"] : item;
            case "supplier_id":
                supplier = stores.supplier[item] !== undefined ? stores.supplier[item]["name"] : item;
                if (this.props.row.hasOwnProperty("suppliers")) {
                    if (supplier === null) {
                        supplier = this.props.row["suppliers"];
                    } else {
                        supplier = this.props.row["suppliers"] + " &nbsp; " + supplier;
                    }
                }

                return (
                    <React.Fragment>
                        <span dangerouslySetInnerHTML={{ __html: supplier }}>
                        </span>
                    </React.Fragment>
                );
            case "webshop_id":
                return this.props.pageData.layout === "orders" && stores.webshop[item] !== undefined
                    ? stores.webshop[item]["name"] : item;
            case "printer_type":
                return stores.printer_type[item] !== undefined ? stores.printer_type[item] : item;
            case "role_type":
                return item === "G" ? "Group" : "User";
            case "direct_delivery":
            case "notify_webshop":
            case "notify_carrier":
            case "notify_supplier":
            case "permission_view":
            case "permission_edit":
            case "enabled":
            case "ignore_merge":
            case "show_printer_dialog":
            case "show_as_dedicated":
                return item === "1" ? <SuccessIcon style={{color: "#4caf50"}}/> :
                    <CancelIcon style={{color: "#e57373"}}/>;
            default:
                return item;
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            general,
            settings,
            item,
        } = this.props;

        if (this.state.zebra) {
            return (
                <React.Fragment>
                    {settings.visible && (
                        <TableCell
                            className={getClassNames(settings)}
                            style={{cursor: "pointer", userSelect: "none"}}
                        >
                            {(general.col_name_show && settings.key !== "row_controls") && (
                                <Typography
                                    className={"tdBefore cut-text"}
                                    color="textSecondary"
                                    display="block"
                                    variant="caption"
                                    style={!general.col_name_follow ? {textAlign: "left", width: "80%"} : {}}
                                >
                                    {settings.name}
                                </Typography>
                            )}
                            {settings.key !== "row_controls"
                                && <Typography color="inherit" className={"cut-text"}
                                              style={{width: "80%"}}>{this.getItem(settings.key, item)}</Typography>}
                        </TableCell>
                    )}
                    {this.state.status_selector_open && (
                        <StatusSelectorDialog statuses={this.props.pageData.order_status_to}
                                              onChange={this.state.statusSelectorCallback}
                                              onCancel={() => {
                                                  this.props.setDisableClick(false);
                                                  this.setState({status_selector_open: false});
                                              }}
                        />
                    )}
                    {settings.key === "row_controls" && <td
                        style={{position: "absolute", right: ".5rem"}}
                        color="inherit">{this.getItem(settings.key, item)}</td>}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {settings.visible && (
                    <TableCell
                        className={getClassNames(settings)}
                        style={{cursor: "pointer", userSelect: "none", whiteSpace: "nowrap"}}
                    >
                        {(general.col_name_show && settings.key !== "row_controls") && (
                            <Typography
                                className={"tdBefore cut-text"}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                                style={!general.col_name_follow ? {textAlign: "left", width: "80%"} : {}}
                            >
                                {settings.name}
                            </Typography>
                        )}
                        <Typography color="inherit">{this.getItem(settings.key, item)}</Typography>
                    </TableCell>
                )}
                {this.state.status_selector_open && (
                    <StatusSelectorDialog statuses={this.props.pageData.order_status_to}
                                          onChange={this.state.statusSelectorCallback}
                                          onCancel={() => {
                                              this.props.setDisableClick(false);
                                              this.setState({status_selector_open: false});
                                          }}
                    />
                )}
            </React.Fragment>
        );
    }
}

TheTableCell.propTypes = {
    acl: PropTypes.any,
    pageData: PropTypes.any,
    stores: PropTypes.any,
    general: PropTypes.any,
    settings: PropTypes.any,
    row: PropTypes.any,
    row_index: PropTypes.any,
    onEdit: PropTypes.any,
    onDelete: PropTypes.any,
    item: PropTypes.any,
    reload: PropTypes.func,
    setDisableClick: PropTypes.func,
    classes: PropTypes.any,
};

TheTableCell.defaultProps = {
    setDisableClick: () => {},
    header: false,
    classes: {}
};

export default TheTableCell;
