/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Control component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import SpeedDial from "../../../common/speedDial";
import Header from "../common/header";
import ProgressBar from "../common/progressBar";
import CodeInputDialog from "../common/codeInputDialog";
import NotificationService from "../../../../services/Notification";
import SettingsDataStore from "../../../../stores/SettingsDataStore";
import OrderSaveDialog from "../common/orderSaveDialog";
import OrderListDialog from "../common/orderListDialog";
import OrderInfoDialog from "../common/orderInfoDialog";
import Package from "./package";
import OrderInfo from "../common/orderInfo";
import SelectCarrier from "../common/selectCarrier";
import PleaseScan from "../common/pleaseScan";
import SaveIcon from "@material-ui/icons/Save";
import BarcodeReader from "../common/barcodeReader";
import FocusLock from "react-focus-lock";
import OrderNotEditableDialog from "../common/orderNotEditableDialog";
import AddPackageNumber from "../../addPackageNumberDialog";


class Control extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.rootRef= React.createRef();

        this.loading = false;
        this.keys = "";
        this.key_time = 0;

        this.state = {
            scannerActive: true,
            carriers: [],
            order_info_dialog: false,
            order_list_dialog: false,
            total_count: 0,
            founded_count: 0,
            actual_founded: false,
            enter_code_dialogue: false,
            speedDial_open: false,
            data: false,
            alert_open: false,
            can_save: false,
            save_dialog: false,
            settings: {
                header: {
                    show_code_input: false
                }
            },
            editable: true,
            notEditableDialog: false,
            message: "",
            lastScanned: "",
            carrier_ids: [],
            status: ""
        };

        this.onChange = this.onChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleClickOrderCode = this.handleClickOrderCode.bind(this);
        this.ordersSuccess = this.ordersSuccess.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.searchItem = this.searchItem.bind(this);
        this.getRowClassname = this.getRowClassname.bind(this);
        this.newOrderAlertClose = this.newOrderAlertClose.bind(this);
        this.newOrderAlertOk = this.newOrderAlertOk.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.checkReadyToSave = this.checkReadyToSave.bind(this);
        this.toggleShowEnterCode = this.toggleShowEnterCode.bind(this);
        this.closeCodeDialogue = this.closeCodeDialogue.bind(this);
        this.preventClose = this.preventClose.bind(this);
        this.saveOrderOk = this.saveOrderOk.bind(this);
        this.saveOrderSuccess = this.saveOrderSuccess.bind(this);
        this.orderListCancel = this.orderListCancel.bind(this);
        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
        this.reset = this.reset.bind(this);
        this.onPackageSizeChange = this.onPackageSizeChange.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.changePackageUnit = this.changePackageUnit.bind(this);
        this.onPackageWeightChange = this.onPackageWeightChange.bind(this);
        this.getCarriers = this.getCarriers.bind(this);
        this.carriersSuccess = this.carriersSuccess.bind(this);
        this.changeCarrier = this.changeCarrier.bind(this);
        this.cancelSaveOrder = this.cancelSaveOrder.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.searchBarcode = this.searchBarcode.bind(this);
        this.notEditableDialogOk = this.notEditableDialogOk.bind(this);
        this.setScannerActive = this.setScannerActive.bind(this);
        this.blurElements = this.blurElements.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.addPackages = this.addPackages.bind(this);
        this.onFieldBlur = this.onFieldBlur.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        let settings = SettingsDataStore.getData(this.props.pageData.layout);

        if (settings !== undefined) {
            this.setState({settings: settings});
        }
        this.getCarriers();
    }

    reset() {
        this.setState({
            order_info_dialog: false,
            order_list_dialog: false,
            total_count: 0,
            founded_count: 0,
            actual_founded: false,
            enter_code_dialogue: false,
            data: false,
            alert_open: false,
            can_save: false,
            save_dialog: false,
            status: ""
        });
    }

    scrollToTop() {
        if (this.state.settings.scroll_to_top_on_scan) {
            this.rootRef.current.scrollTo(0, 0);
        }
    }

    /**
     * Show/Hide enter code dialogue
     */
    toggleShowEnterCode() {
        this.setState({enter_code_dialogue: !this.state.enter_code_dialogue});
    }

    /**
     * Close the code input dialogue
     * @returns {boolean}
     */
    closeCodeDialogue() {
        if (!this.state.enter_code_dialogue) {
            return false;
        }
        this.setState({enter_code_dialogue: false});
    }

    /**
     * Check order is ready to save
     */
    checkReadyToSave(openSaveDialog = false) {
        let total_count = this.state.data.packages.length,
            founded_count = 0,
            _can_save = true;

        this.state.data.packages.forEach(function (pack) {
            if (pack.hasOwnProperty("package_unit") && pack.package_unit && Number(pack.weight) > 0 && Number(pack.dimension.height) > 0 && Number(pack.dimension.width) > 0 && Number(pack.dimension.length) > 0) {
                founded_count++;
            }
            else {
                _can_save = false;
            }
        });

        if (this.state.data.carrier_id === null || this.state.data.carrier_id === undefined || this.state.data.carrier_id === '') {
            _can_save = false;
        }

        this.setState({can_save: _can_save, save_dialog: openSaveDialog && _can_save, total_count: total_count, founded_count: founded_count});
    }

    /**
     * Handle new order request form the user
     * @returns {boolean}
     */
    newOrder() {
        if (this.state.data !== false) {
            this.setState({alert_open: true, speedDial_open: false});
            return false;
        }

        this.reset();
    }


    /**
     * Return the row classname depend on founded calculation
     * @param pack
     * @returns {string}
     */
    getRowClassname(pack) {
        let _class = " normal";

        if (this.state.actual_founded !== false) {
            if (pack.package_id === this.state.actual_founded.item.package_id) {
                _class = " yellow";
            }
        }

        if (pack.hasOwnProperty("package_unit") && pack.package_unit && Number(pack.weight) > 0 && Number(pack.dimension.height) > 0 && Number(pack.dimension.width) > 0 && Number(pack.dimension.length) > 0) {
            _class = " green";
        }
        return _class;
    }

    /**
     * Check the package is actually selected
     * @param pack
     * @returns {boolean}
     */
    isSelected(pack) {
        if (this.state.actual_founded !== false) {
            if (pack.package_id === this.state.actual_founded.item.package_id) {
                return true;
            }
        }

        return false;
    }

    /**
     * Handle input value changes
     * @param e
     */
    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    /**
     * Handle package unit change
     * @param e
     */
    changePackageUnit(e) {
        let pack = this.state.actual_founded.item;

        pack.package_unit = e.target.value;
        this.blurElements();
        this.checkReadyToSave(true);
    }

    /**
     * Handle package size input value changes
     * and accept empty and number values (included decimal)
     * @param e
     * @param autoSave
     */
    onPackageSizeChange(e, autoSave = false) {
        let pack = this.state.actual_founded.item,
            val = e.target.value,
            pattern = /^-?\d*\.?\d*$/;

        if (pattern.test(val)) {
            pack.dimension[e.target.name] = val;
            this.checkReadyToSave(autoSave);
        }
    }

    /**
     * Handle package weight input value changes
     * and accept empty and number values (included decimal)
     * @param e
     */
    onPackageWeightChange(e) {
        let pack = this.state.actual_founded.item,
            val = e.target.value,
            pattern = /^-?\d*\.?\d*$/;

        if (pattern.test(val)) {
            pack.weight = val;
            this.checkReadyToSave();
        }
    }

    onFieldBlur() {
        if (this.state.can_save) {
            this.setState({ save_dialog: true });
        }
    }

    handleClickOrderCode(order_code) {
        if (order_code === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({lastScanned: order_code});
        this.closeCodeDialogue();
        this.fetchData("control/" + this.props.pageData.menu_id + "/" + order_code, this.ordersSuccess);
    }

    loadOrder(id) {
        if (id === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({order_list_dialog: false});
        this.fetchData("control/" + this.props.pageData.menu_id + "/" + id, this.ordersSuccess);
    }

    searchItem(item_code) {
        let self = this,
            isFounded = false;

        if (item_code === "") {
            NotificationService.showNotification("error", "The item code is empty");
            return false;
        }

        if (!this.state.data.hasOwnProperty("packages")) {
            NotificationService.showNotification("error", "Please enter the order code first");
            return false;
        }

        this.setState({lastScanned: item_code});
        this.closeCodeDialogue();

        this.state.data.packages.forEach(function (pack, pack_index) {
            if (pack.ean === item_code) {
                isFounded = true;
                self.setState({actual_founded: {item: pack, index: pack_index}, item_code: ""});
            }
        });

        if (!isFounded) {
            this.setState({actual_founded: false, lastScanned: this.state.item_code});
            NotificationService.showNotification("error", "The item not found in this order");
            return false;
        }
        self.scrollToTop();
        self.checkReadyToSave();
    }

    ordersSuccess(result){
        this.loading = false;

        if (result.hasOwnProperty("_embedded")) {
            if (result._embedded.length === 1) {
                if (result._embedded[0].hasOwnProperty("carrier_ids") && result._embedded[0].carrier_ids !== "") {
                    const carrier_ids = result._embedded[0].carrier_ids.split(",");
                    this.setState({carrier_ids});
                } else {
                    this.setState({carrier_ids: []});
                }

                if (result._embedded[0].hasOwnProperty("packages")) {
                    if (!result._embedded[0].editable) {
                        this.setState({
                            data: false,
                            orders: [],
                            just_item_info: false,
                            notEditableDialog: !result._embedded[0].editable,
                            editable: result._embedded[0].editable,
                            message: result._embedded[0].message,
                            status: result._embedded[0].status
                        });
                        return;
                    }

                    if (this.state.carrier_ids.length === 1) {
                        result._embedded[0].carrier_id = this.state.carriers.filter(carrier => this.state.carrier_ids.includes(carrier.partner_id))[0].carrier_id;
                    }
                    this.setState({data: result._embedded[0], orders: []});
                    this.checkReadyToSave();
                    return;
                }

                if (result._embedded[0].hasOwnProperty("increment_id")) {
                    this.loadOrder(result._embedded[0].increment_id);
                    this.setState({orders: []});
                    return;
                }

            }

            this.setState({orders: result._embedded, order_list_dialog: true});
            self.scrollToTop();
        }
    }

    getCarriers() {
        LoaderService.getData("carrier", "", this.carriersSuccess);
    }

    carriersSuccess(result) {
        if(result.hasOwnProperty("_embedded")) {
            this.setState({carriers: result._embedded.carrier});
            return true;
        }
        NotificationService.showNotification("error", "The carrier list not loaded");
    }

    changeCarrier(e) {
        let data = this.state.data;
        data.carrier_id = e.target.value;
        this.blurElements();
        this.checkReadyToSave(true);
    }

    fetchData(what, callback){
        this.loading = true;
        LoaderService.getData(what, "", callback);
    }

    newOrderAlertClose() {
        this.setState({alert_open: false});
    }

    newOrderAlertOk() {
        this.reset();
    }

    preventClose(e) {
        e.preventDefault();
    }

    saveOrderOk(status) {
        const _data = Object.assign(this.state.data, {status});
        LoaderService.postData("control/" + this.props.pageData.menu_id + "/" + this.state.data.increment_id, _data, this.saveOrderSuccess);
    }

    downloadFile(_embedded) {
        for (let key in _embedded) {
            if (_embedded[key].hasOwnProperty("download")) {
                let linkSource = `data:application/file;base64,${_embedded[key].download.content_base64}`;
                let downloadLink = document.createElement("a");
                let fileName = _embedded[key].download.filename;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }

            if (_embedded[key].hasOwnProperty("download_pdf")) {
                let linkSource = `data:application/file;base64,${_embedded[key].download_pdf.content_base64}`;
                let downloadLink = document.createElement("a");
                let fileName = _embedded[key].download_pdf.filename;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }

            if (_embedded[key].hasOwnProperty("download_label")) {
                let linkSource = `data:application/file;base64,${_embedded[key].download_label.content_base64}`;
                let downloadLink = document.createElement("a");
                let fileName = _embedded[key].download_label.filename;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }
    }

    saveOrderSuccess(result) {
        if(result.hasOwnProperty("_embedded")) {
            this.downloadFile(result._embedded);
            this.reset();
        }
    }

    cancelSaveOrder() {
        this.setState({save_dialog: false});
    }

    orderListCancel() {
        this.setState({order_list_dialog: false});
    }

    closeOrderInfo() {
        this.setState({order_info_dialog: false});
    }

    openOrderInfo() {
        this.setState({order_info_dialog: true});
    }

    searchBarcode(keys) {
        if (this.state.data !== false) {
            if (keys !== undefined) {
                this.searchItem(keys);
            }
        }
        else {
            if (keys !== undefined) {
                this.handleClickOrderCode(keys);
            }
        }
    }

    notEditableDialogOk() {
        this.setState({notEditableDialog: false});
    }

    setScannerActive(val) {
        this.setState({scannerActive: val});
    }

    blurElements() {
        setTimeout(function () {
            if (document.activeElement) {
                document.activeElement.blur();
                window.focus();
            }
        }, 100);
    }

    /*

     */
    addPackages(packageCount) {
        let formattedCount = packageCount < 10 ? "0" + packageCount : packageCount;

        let data = Object.assign({}, this.state.data);
        data.packages = new Array(packageCount).fill("");
        data.packages = data.packages.map((item, idx) => {
            let formattedIndex = idx < 9 ? "0" + (idx + 1) : idx + 1;
            return ({
                package_id: idx + 1,
                ean: this.state.data.increment_id + formattedIndex + formattedCount,
                accessory: false,
                qty: 1,
                weight: "",
                dimension: {
                    height: "",
                    width: "",
                    length: ""
                },
                items: []
            });
        });
        this.setState({data, total_count: packageCount}, this.checkReadyToSave);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            pageData
        } = this.props;

        if (this.state.data.hasOwnProperty("packages")
            && this.state.data.hasOwnProperty("increment_id")
            && this.state.data.packages.length === 0
        ) {
            return <AddPackageNumber addPackageCount={this.addPackages} newOrder={this.reset}/>;
        }

        return (
            <div className={classes.tableRoot + " hideInputLabels"} ref={this.rootRef}>
                <FocusLock
                    disabled={!this.state.scannerActive}
                >
                    <BarcodeReader
                        onEnter={this.searchBarcode}
                        preventDefault={false}
                        disabled={!this.state.scannerActive || this.state.notEditableDialog || this.state.save_dialog || this.state.order_info_dialog || this.state.alert_open || this.state.order_list_dialog}
                    />
                </FocusLock>
                <div style={{display: "flex", width: "100%"}}>
                <Header
                    increment_id={this.state.data.increment_id}
                    classes = {classes}
                    handleClickOrderCode = {this.handleClickOrderCode}
                    searchItem = {this.searchItem}
                    status = {this.state.data.status}
                    webshopStatus={this.state.data.webshop_status_label}
                    vendors = {this.state.data.vendors}
                    created = {this.state.data.created_at_date}
                    settings = {this.state.settings.header}
                    show = {this.state.data.hasOwnProperty("packages")}
                    showItemInfo = {this.state.settings.show_product_on_order_list}
                    just_item_info = {this.state.just_item_info}
                />

                {this.state.data.hasOwnProperty("packages") && (
                    <div style={{width: "50%"}}>
                    <ProgressBar
                        lastScanned={this.state.lastScanned}
                        total={this.state.total_count}
                        actual={this.state.founded_count}
                        percentOne={this.state.founded_count === 0 ? 0 : Number(this.state.founded_count) / Number(this.state.total_count) * 100 }
                    />
                    </div>
                )}
                </div>

                {!this.state.data.hasOwnProperty("packages") && (
                    <PleaseScan title={pageData.title} />
                )}

                {this.state.data.hasOwnProperty("packages") && (
                    <Package
                        searchItem={this.searchItem}
                        packages={this.state.data.packages}
                        units={this.state.data.packing_units}
                        changePackageUnit={this.changePackageUnit}
                        getRowClassname={this.getRowClassname}
                        onPackageSizeChange={this.onPackageSizeChange}
                        onPackageWeightChange={this.onPackageWeightChange}
                        onFieldBlur={this.onFieldBlur}
                        actualFounded = {this.state.actual_founded}
                    />
                )}
                {this.state.data.hasOwnProperty("packages") && (
                    <OrderInfo
                        order={this.state.data}
                    />
                )}

                {this.state.data.hasOwnProperty("packages") && (
                    <SelectCarrier
                        carriers={this.state.carrier_ids.length
                            ? this.state.carriers.filter(carrier => {
                                let showAsDedicated = carrier.hasOwnProperty("show_as_dedicated") && carrier.show_as_dedicated === "1";
                                let disabled = carrier.hasOwnProperty("enabled") && carrier.enabled === "0";
                                return (this.state.carrier_ids.includes(carrier.partner_id) || showAsDedicated) && !disabled;
                            })
                            : this.state.carriers.filter(carrier => {
                                let disabled = carrier.hasOwnProperty("enabled") && carrier.enabled === "0";
                                return !disabled;
                            })}
                        selectedCarrier={this.state.data.carrier_id}
                        changeCarrier={this.changeCarrier}
                    />
                )}

                {(this.state.data || !this.state.settings.header.show_code_input) && (
                    <SpeedDial
                        actions={[
                            {icon: <SearchIcon />, label: "Search", className: !this.state.data && !this.state.enter_code_dialogue ? "highlight" : "", condition: !this.state.settings.header.show_code_input, onClick: () => this.toggleShowEnterCode()},
                            {icon: <InfoIcon />, label: "Details",  condition: this.state.data, onClick: () => this.openOrderInfo()},
                            {icon: <SaveIcon />, label: "Save",condition: this.state.can_save, onClick: () => this.saveOrderOk()},
                            {icon: <AddCircleOutlineIcon />, label: "New", condition: this.state.data, onClick: () => this.newOrder()},
                        ]}
                        isHighlit={(!this.state.data && !this.state.enter_code_dialogue) || this.state.can_save}>
                    </SpeedDial>
                )}
                <Dialog
                    title="Are you sure to load new order?"
                    open={this.state.alert_open}
                    onClose={this.newOrderAlertClose}
                    onCancel={this.newOrderAlertClose}
                    onOk={this.newOrderAlertOk}
                    okText="Agree"
                    cancelText="Disagree"
                >
                    <DialogContentText>
                        All not saved data for this order wil be lost
                    </DialogContentText>
                </Dialog>

                <OrderListDialog
                    open={this.state.order_list_dialog}
                    onClose={this.orderListCancel}
                    onCancel={this.orderListCancel}
                    onSelect={this.loadOrder}
                    orders={this.state.orders}
                />

                <OrderInfoDialog
                    open={this.state.order_info_dialog}
                    onClose={this.closeOrderInfo}
                    onCancel={this.closeOrderInfo}
                    order={this.state.data}
                />

                <OrderSaveDialog
                    statuses={this.props.pageData.order_status_to}
                    open={this.state.save_dialog}
                    onClose={this.preventClose}
                    onOk={this.saveOrderOk}
                    onCancel={this.cancelSaveOrder}
                />

                <OrderNotEditableDialog
                    open={this.state.notEditableDialog}
                    onClose={this.preventClose}
                    onOk={this.notEditableDialogOk}
                    message={this.state.message}
                    status={this.state.status}
                />

                <CodeInputDialog
                    open={this.state.enter_code_dialogue}
                    onClose={this.toggleShowEnterCode}
                    onCancel={this.toggleShowEnterCode}
                    onChange={this.onChange}
                    hasOrder={this.state.data !== false}
                    searchOrder={this.handleClickOrderCode}
                    searchItem={this.searchItem}
                />
            </div>
        );
    }
}

Control.propTypes = {
    pageData: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    ACL: PropTypes.any
};

Control.defaultProps = {
    url: false,
    classes: {}
};

export default Control;
