/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import SpeedDial from "../../../common/speedDial";
import CodeInputDialog from "../common/codeInputDialog";
import NotificationService from "../../../../services/Notification";
import SettingsDataStore from "../../../../stores/SettingsDataStore";
import OrderSaveDialog from "../common/orderSaveDialog";
import OrderView from "../common/orderView";
import PleaseScan from "../common/pleaseScan";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import BarcodeReader from "../common/barcodeReader";
import FocusLock from "react-focus-lock";
import OrderNotEditableDialog from "../common/orderNotEditableDialog";
import RoleStore from "../../../../stores/RoleStore";
import userDataStore from "../../../../stores/UserDataStore";
import Settings from "./settings";
import SettingsIcon from "@material-ui/icons/Settings";
import FullscreenLoaderService from "../../../../services/FullscreenLoaderService";
import notificationService from "../../../../services/Notification";
import {checkDownloadables} from "../../utils/downloadFile";

class Order extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.rootRef= React.createRef();
        this.loading = false;
        this.keys = "";
        this.key_time = 0;

        this.state = {
            scannerActive: true,
            enter_code_dialogue: false,
            speedDial_open: false,
            show_settings: false,
            data: false,
            alert_open: false,
            can_save: false,
            save_dialog: false,
            settings: {
                header: {
                    show_code_input: false,
                    fields: {}
                }
            },
            tableSettings: {
                header: [],
                general: {},
                filter: []
            },
            editable: true,
            notEditableDialog: false,
            message: "",
            lastScanned: "",
            status: "",
            user: userDataStore.getData("user")
        };

        this.onChange = this.onChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleClickOrderCode = this.handleClickOrderCode.bind(this);
        this.ordersSuccess = this.ordersSuccess.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.newOrderAlertClose = this.newOrderAlertClose.bind(this);
        this.newOrderAlertOk = this.newOrderAlertOk.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.checkReadyToSave = this.checkReadyToSave.bind(this);
        this.toggleShowEnterCode = this.toggleShowEnterCode.bind(this);
        this.closeCodeDialogue = this.closeCodeDialogue.bind(this);
        this.preventClose = this.preventClose.bind(this);
        this.saveOrderOk = this.saveOrderOk.bind(this);
        this.saveOrderSuccess = this.saveOrderSuccess.bind(this);
        this.reset = this.reset.bind(this);
        this.cancelSaveOrder = this.cancelSaveOrder.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.searchBarcode = this.searchBarcode.bind(this);
        this.notEditableDialogOk = this.notEditableDialogOk.bind(this);
        this.setScannerActive = this.setScannerActive.bind(this);
        this.blurElements = this.blurElements.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.handleShowHideTableSettings = this.handleShowHideTableSettings.bind(this);
        this.handleSaveTableSettings = this.handleSaveTableSettings.bind(this);
        this.saveTableSettingsSuccess = this.saveTableSettingsSuccess.bind(this);
        this.loadTableSettings = this.loadTableSettings.bind(this);
        this.loadTableSettingsSuccess = this.loadTableSettingsSuccess.bind(this);
        this.headerHasColumn = this.headerHasColumn.bind(this);
        this.loadFieldSettings = this.loadFieldSettings.bind(this);
        this.loadFieldSettingsSuccess = this.loadFieldSettingsSuccess.bind(this);
        this.reloadColumns = this.reloadColumns.bind(this);
        this.getHeaderDefaultSet = this.getHeaderDefaultSet.bind(this);
        this.print = this.print.bind(this);
        this.printSuccess = this.printSuccess.bind(this);
        this.checkAvailableAction = this.checkAvailableAction.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        let settings = SettingsDataStore.getData(this.props.pageData.layout);

        this.loadTableSettings();
        this.loadFieldSettings();

        if (settings !== undefined) {
            this.setState({settings: settings});
        }
    }

    componentDidUpdate() {
        this.checkTableSettings(this.props.pageData);
    }

    componentWillUnmount() {
        RoleStore.removeAllListeners();
    }

    loadTableSettings() {
        LoaderService.getData(
            "table_settings/table/" + this.props.pageData.menu_id + "/role_id/" + this.state.user.role_id,
            "",
            this.loadTableSettingsSuccess,
            this.notFoundTableSettings,
            false
        );
    }

    loadFieldSettings() {
        LoaderService.getData(
            "order_layout/" + this.props.pageData.menu_id + "/fields",
            "",
            this.loadFieldSettingsSuccess
        );
    }

    loadTableSettingsSuccess(res) {
        if (res.settings) {
            let settings = JSON.parse(res.settings);

            if (!settings.hasOwnProperty("general")) {
                settings.general = {};
            }

            if (!settings.hasOwnProperty("filter")) {
                settings.filter = [];
            }

            this.setState({tableSettings: settings});
        }
    }

    loadFieldSettingsSuccess(res) {
        let settings = this.state.settings;
        settings.header.fields = res._embedded[0];
        this.setState({settings: settings});
    }

    checkTableSettings(pageData) {
        if (pageData.menu_id !== this.menu_id) {
            this.menu_id = pageData.menu_id;
            this.loadTableSettings();
        }
    }

    checkAvailableAction(action) {
        return this.state.data && this.state.tableSettings &&
            this.state.tableSettings.general.hasOwnProperty(action) && this.state.tableSettings.general[action];
    }

    handleShowHideTableSettings() {
        let self = this;
        setTimeout(function () {
            self.setState({show_settings: !self.state.show_settings, speedDial_open: false});

        },100);
    }

    handleSaveTableSettings(settings, general, filter) {
        let role_id = this.state.user.role_id;
        FullscreenLoaderService.showLoader();

        LoaderService.postData("table_settings/table/" + this.props.pageData.menu_id + "/role_id/" + role_id, {
            table: this.props.pageData.menu_id,
            role_id: role_id,
            settings: JSON.stringify({
                ver: "0.1.1",
                header: settings,
                general: general,
                filter: filter
            })
        }, this.saveTableSettingsSuccess);
        this.setState({tableSettings: {header: settings, general: general, filter: filter}, speedDial_open: false});
    }

    saveTableSettingsSuccess(result) {
        if (result.hasOwnProperty("table_settings_id")) {
            this.setState({show_settings: false});
            notificationService.showNotification("success", "Table settings saved");
        }
        FullscreenLoaderService.hideLoader();
    }

    /**
     * Search key in header
     * @param key
     * @returns {boolean}
     */
    headerHasColumn(key) {
        let result = false;
        this.state.tableSettings.header.forEach(function (item) {
            if (item.key === key) {
                result = true;
            }
        });
        return result;
    }

    /**
     * The default header set
     * @param key
     * @returns {{xxs: {visible: boolean, align: string, font: string}, visible: boolean, s: {visible: boolean, align: string, font: string}, xl: {visible: boolean, align: string, font: string}, name: *, xs: {visible: boolean, align: string, font: string}, l: {visible: boolean, align: string, font: string}, m: {visible: boolean, align: string, font: string}, xxl: {visible: boolean, align: string, font: string}, key: *}}
     */
    getHeaderDefaultSet(key) {
        return {
            key: key,
            name: key.replace(/[_]/g, " "),
            visible: true,
            xxl: {
                align: "left",
                visible: true,
                font: "normal",

            },
            xl: {
                align: "left",
                visible: true,
                font: "normal",
            },
            l: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            m: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            s: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            xs: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            xxs: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            }
        };
    }

    /**
     * Search and add new columns to header settings
     */
    reloadColumns() {
        FullscreenLoaderService.showLoader();
        let header = this.state.tableSettings.header,
            founded = 0;

        for (var key in this.state.settings.header.fields) {
            if ( key !== "_links" && key !== "password" && !this.headerHasColumn(key)) {
                header.push(this.getHeaderDefaultSet(key));
                founded++;
            }
        }
        FullscreenLoaderService.hideLoader();
        if (founded > 0) {
            notificationService.showNotification("success", founded + "new column(s) found");
            this.forceUpdate();
        } else {
            notificationService.showNotification("info", "No new column found");
        }
    }

    reset() {
        this.setState({
            enter_code_dialogue: false,
            data: false,
            alert_open: false,
            can_save: false,
            save_dialog: false,
            status: ""
        });
    }

    scrollToTop() {
        if (this.state.settings.scroll_to_top_on_scan) {
            this.rootRef.current.scrollTo(0, 0);
        }
    }

    /**
     * Show/Hide enter code dialogue
     */
    toggleShowEnterCode() {
        this.setState({enter_code_dialogue: !this.state.enter_code_dialogue});
    }

    /**
     * Close the code input dialogue
     * @returns {boolean}
     */
    closeCodeDialogue() {
        if (!this.state.enter_code_dialogue) {
            return false;
        }
        this.setState({enter_code_dialogue: false});
    }

    /**
     * Check order is ready to save
     */
    checkReadyToSave() {
        let _can_save = false;
        this.setState({can_save: _can_save, save_dialog: _can_save});
    }

    /**
     * Handle new order request form the user
     * @returns {boolean}
     */
    newOrder() {
        if (this.state.data !== false) {
            this.setState({alert_open: true, speedDial_open: false});
            return false;
        }

        this.reset();
    }

    /**
     * Handle input value changes
     * @param e
     */
    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleClickOrderCode(order_code) {
        if (order_code === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({lastScanned: order_code});
        this.closeCodeDialogue();
        this.fetchData("order_layout/" + this.props.pageData.menu_id + "/" + order_code, this.ordersSuccess);
    }

    loadOrder(id) {
        if (id === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.fetchData("order_layout/" + this.props.pageData.menu_id + "/" + id, this.ordersSuccess);
    }

    ordersSuccess(result){
        this.loading = false;

        if (result.hasOwnProperty("_embedded")) {
            if (result._embedded.length === 1) {
                if (result._embedded[0].hasOwnProperty("increment_id")) {
                    if (!result._embedded[0].editable) {
                        this.setState({
                            data: false,
                            orders: [],
                            just_item_info: false,
                            notEditableDialog: !result._embedded[0].editable,
                            editable: result._embedded[0].editable,
                            message: result._embedded[0].message,
                            status: result._embedded[0].status
                        });
                        return;
                    }

                    this.setState({data: result._embedded[0], orders: []});
                    this.checkReadyToSave();
                    return;
                }

                if (result._embedded[0].hasOwnProperty("increment_id")) {
                    this.loadOrder(result._embedded[0].increment_id);
                    this.setState({orders: []});
                    return;
                }
            }

            this.setState({orders: result._embedded});
            self.scrollToTop();
        }
    }

    fetchData(what, callback){
        this.loading = true;
        LoaderService.getData(what, "", callback);
    }

    newOrderAlertClose() {
        this.setState({alert_open: false});
    }

    newOrderAlertOk() {
        this.reset();
    }

    preventClose(e) {
        e.preventDefault();
    }

    saveOrderOk(status) {
        let _data = Object.assign(
            this.state.data,
            status === null ? {} : {status: status}
        );
        LoaderService.postData(
            "order_layout/" + this.props.pageData.menu_id + "/" + this.state.data.increment_id,
            _data,
            this.saveOrderSuccess
        );
    }

    downloadFile(_embedded) {
        for (let key in _embedded) {
            if (_embedded[key].hasOwnProperty("download")) {
                const linkSource = `data:application/file;base64,${_embedded[key].download.content_base64}`;
                const downloadLink = document.createElement("a");
                const fileName = _embedded[key].download.filename;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }
    }

    saveOrderSuccess(result) {
        if (result.hasOwnProperty("_embedded")) {
            this.downloadFile(result._embedded);
            this.reset();
        }
    }

    cancelSaveOrder() {
        this.setState({save_dialog: false});
    }

    saveOrder() {
        let statusTo = this.props.pageData.order_status_to;
        let statusToCount = Object.keys(statusTo).length;

        if (statusToCount > 1) {
            this.setState({save_dialog: true});
        } else if (statusToCount === 1) {
            this.saveOrderOk(statusTo[0]);
        } else {
            this.saveOrderOk(null);
        }
    }

    searchBarcode(keys) {
        if (!this.state.data && keys !== undefined) {
            this.handleClickOrderCode(keys);
        }
    }

    notEditableDialogOk() {
        this.setState({notEditableDialog: false});
    }

    setScannerActive(val) {
        this.setState({scannerActive: val});
    }

    blurElements() {
        setTimeout(function () {
            if (document.activeElement) {
                document.activeElement.blur();
                window.focus();
            }
        }, 100);
    }

    print() {
        LoaderService.postData(
            "packing_slip",
            {"order_id": [this.state.data.entity_id]},
            this.printSuccess
        );
    }

    printSuccess(res) {
        checkDownloadables(res);

        if (res.hasOwnProperty("success") && res.success === true) {
            notificationService.showNotification("success", "Print success");
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            pageData,
            acl
        } = this.props;

        return (
            <div className={classes.tableRoot + " hideInputLabels"} ref={this.rootRef}>
                <FocusLock
                    disabled={!this.state.scannerActive}
                >
                    <BarcodeReader
                        onEnter={this.searchBarcode}
                        preventDefault={false}
                        disabled={!this.state.scannerActive || this.state.notEditableDialog || this.state.save_dialog || this.state.alert_open}
                    />

                    {!this.state.data.hasOwnProperty("increment_id") && !this.state.show_settings && (
                        <PleaseScan title={pageData.title} />
                    )}

                    {this.state.data.hasOwnProperty("increment_id") && !this.state.show_settings && (
                        <OrderView
                            order={this.state.data}
                            pageData={this.props.pageData}
                            tableSettings={this.state.tableSettings}
                            cancelOrder={this.newOrder}
                            saveOrder={this.saveOrder}
                            print={this.print}
                        />
                    )}

                    {this.state.show_settings && (
                        <Settings
                            pageData={pageData}
                            classes={classes}
                            header={this.state.tableSettings.header}
                            general={this.state.tableSettings.general}
                            filter={this.state.tableSettings.filter}
                            handleShowHideTableSettings={this.handleShowHideTableSettings}
                            handleSaveTableSettings={this.handleSaveTableSettings}
                            reloadColumns={this.reloadColumns}
                        />
                    )}

                    {((this.state.data || !this.state.settings.header.show_code_input) && !this.state.show_settings) && (
                        <SpeedDial
                            actions={[
                                {icon: <SearchIcon />, label: "Search", className: !this.state.data && !this.state.enter_code_dialogue ? "highlight" : "", condition: !this.state.settings.header.show_code_input && !this.state.data, onClick: () => this.toggleShowEnterCode()},
                                {icon: <SaveIcon />, label: "Lezár", onClick: () => this.saveOrder(), condition: this.checkAvailableAction("row_save")},
                                {icon: <PrintIcon />, label: "Nyomtat", onClick: () => this.print(), condition: this.checkAvailableAction("row_print")},
                                {icon: <AddCircleOutlineIcon />, label: "Vissza", condition: this.state.data, onClick: () => this.newOrder()},
                                {icon: <SettingsIcon />, label: "Settings", tooltipTitle: "Settings", onClick: () => this.handleShowHideTableSettings(), condition: acl.hasOwnProperty("admin")},
                            ]}
                            isHighlit={(!this.state.data && !this.state.enter_code_dialogue) || this.state.can_save}>
                        </SpeedDial>
                    )}
                    <Dialog
                        title="Are you sure to load new order?"
                        open={this.state.alert_open}
                        onClose={this.newOrderAlertClose}
                        onCancel={this.newOrderAlertClose}
                        onOk={this.newOrderAlertOk}
                        okText="Agree"
                        cancelText="Disagree"
                    >
                        <DialogContentText>
                            All not saved data for this order wil be lost
                        </DialogContentText>
                    </Dialog>

                    <OrderSaveDialog
                        statuses={this.props.pageData.order_status_to}
                        open={this.state.save_dialog}
                        onClose={this.preventClose}
                        onOk={this.saveOrderOk}
                        onCancel={this.cancelSaveOrder}
                    />

                    <OrderNotEditableDialog
                        open={this.state.notEditableDialog}
                        onClose={this.preventClose}
                        onOk={this.notEditableDialogOk}
                        message={this.state.message}
                        status={this.state.status}
                    />

                    <CodeInputDialog
                        open={this.state.enter_code_dialogue}
                        onClose={this.toggleShowEnterCode}
                        onCancel={this.toggleShowEnterCode}
                        onChange={this.onChange}
                        hasOrder={this.state.data !== false}
                        searchOrder={this.handleClickOrderCode}
                    />
                </FocusLock>
            </div>
        );
    }
}

Order.propTypes = {
    pageData: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    acl: PropTypes.any
};

Order.defaultProps = {
    url: false,
    classes: {},
    acl: {}
};

export default Order;